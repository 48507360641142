import { useAuth0 } from "@auth0/auth0-react";
import { Box, VStack } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { DotLoader } from "react-spinners";

import * as logo from "../../assets/header4.png";
import { AuthStatus, KaiBridge } from "../bridge";
import Settings from "../settings";
import { FeedItem } from "../shared-types";
import { FeedItemContent } from "./feed-item-content";

type FeedItemContainerProps = {
  kai: KaiBridge;
  inventory?: boolean;
};

export function FeedItemContainer({
  kai,
  inventory = false,
}: FeedItemContainerProps): JSX.Element {
  const [feedItem, setFeedItem] = useState<FeedItem | null>(null);
  const id = useParams<{ feedItemId: string }>().feedItemId;
  const { isAuthenticated } = useAuth0();

  useEffect(() => {
    if (!id) {
      return () => {};
    }
    const interval = setInterval(async () => {
      if (!isAuthenticated) {
        const response = await fetch(`${Settings.API_HOST}/feed/${id}`);
        const result: FeedItem = (await response.json()) as FeedItem;
        setFeedItem(result);
      } else {
        const status: AuthStatus = await kai.getAuthStatus();
        if (status.bearer) {
          const response = await fetch(`${Settings.API_HOST}/feed/${id}`, {
            headers: {
              Authorization: `Bearer ${status.bearer}`,
            },
          });
          const result: FeedItem = (await response.json()) as FeedItem;
          setFeedItem(result);
        } else {
          const response = await fetch(`${Settings.API_HOST}/feed/${id}`);
          const result: FeedItem = (await response.json()) as FeedItem;
          setFeedItem(result);
        }
      }
    }, 2000);
    return () => {
      clearInterval(interval);
    };
  }, [id, isAuthenticated, kai]);

  return (
    <Box className="vp" height="full">
      <VStack>
        <Link to="/">
          <Box>
            <img
              width="430px"
              height="80px"
              src={logo}
              alt={"Logo that is a blend of 80's cyber outrun and 90's extreme"}
            />
          </Box>
        </Link>
        {feedItem && feedItem._id ? (
          <FeedItemContent
            inventory={inventory}
            feedItem={feedItem}
            muted
            kai={kai}
          />
        ) : (
          <DotLoader size={150} />
        )}
      </VStack>
    </Box>
  );
}

export default FeedItemContainer;
