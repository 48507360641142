import { Auth0Provider } from "@auth0/auth0-react";
import { ChakraProvider } from "@chakra-ui/react";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import * as React from "react";
import * as ReactDOM from "react-dom";

import { App } from "./app";

Sentry.init({
  // eslint-disable-next-line no-secrets/no-secrets
  dsn: "https://1ad981ba637041afb517e8e09f697a54@o1200303.ingest.sentry.io/6326578",
  integrations: [new BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1,
});

ReactDOM.render(
  <Auth0Provider
    domain="dev-ajfk-6oq.us.auth0.com"
    // eslint-disable-next-line no-secrets/no-secrets
    clientId="7RXhqquSD7dPdz5tq82ZkYBhahEGf3T3"
    redirectUri={window.location.origin}
    audience="https://backend.kaimerra.com"
    scope="user"
  >
    <ChakraProvider>
      <App />
    </ChakraProvider>
  </Auth0Provider>,

  document.querySelector("#app")
);
