import { useAuth0 } from "@auth0/auth0-react";
import { Box, Button, Flex, Stack } from "@chakra-ui/react";
import React from "react";
import { Link } from "react-router-dom";

export function Header(): JSX.Element {
  const { user, isAuthenticated, loginWithRedirect, logout } = useAuth0();

  return (
    <Box>
      <Flex
        minH="60px"
        py={{ base: 2 }}
        px={{ base: 4 }}
        borderBottom={1}
        borderStyle="solid"
        align="center"
      >
        <Stack
          flex={{ base: 1, md: 0 }}
          justify="flex-end"
          direction="row"
          spacing={6}
        >
          {!isAuthenticated && (
            <Button
              display={{ base: "none", md: "inline-flex" }}
              colorScheme="blue"
              size="xs"
              onClick={loginWithRedirect}
            >
              Login
            </Button>
          )}
          {isAuthenticated && (
            <>
              <Box color="white" display={{ base: "none", md: "inline-flex" }}>
                <Link to="/profile">
                  <span>{user?.name}</span>
                </Link>
              </Box>
              <Button
                display={{ base: "none", md: "inline-flex" }}
                colorScheme="blue"
                size="xs"
                onClick={() => logout({ returnTo: window.location.origin })}
              >
                Logout
              </Button>
            </>
          )}
        </Stack>
      </Flex>
    </Box>
  );
}

export default Header;
