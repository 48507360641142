import { Box, Heading, Image } from "@chakra-ui/react";

import { Annotation } from "../shared-types";

interface InventoryProps {
  annotations: Annotation[];
}

export function Inventory({ annotations }: InventoryProps): JSX.Element {
  return (
    <>
      <Heading as="h4">Inventory</Heading>
      <Box
        padding={4}
        w="100%"
        maxW="900px"
        mx="auto"
        bg="gray.800"
        sx={{ columnCount: [1, 2, 3], columnGap: "8px" }}
      >
        {annotations.map((ann, i) => (
          // eslint-disable-next-line react/no-array-index-key
          <div key={i}>
            <p>{ann.category}</p>
            <Image
              boxSize="100px"
              objectFit="scale-down"
              src={ann.extractedImage.url}
            />
          </div>
        ))}
      </Box>
    </>
  );
}

export default Inventory;
