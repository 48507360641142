import { useAuth0 } from "@auth0/auth0-react";
import { Box, HStack } from "@chakra-ui/react";
import { HashRouter as Router, Route, Routes } from "react-router-dom";

import { AuthStatus, KaiBridge } from "./bridge";
import { ChannelContainer } from "./feed/channel-container";
import { FeedContainer } from "./feed/feed-container";
import { FeedItemContainer } from "./feed/feed-item-container";
import { Header } from "./header";
import { Profile } from "./profile";

const registerServiceWorker = async (): Promise<void> => {
  if ("serviceWorker" in navigator) {
    try {
      const registration = await navigator.serviceWorker.register(
        new URL("sw.js", import.meta.url),
        {
          scope: "/",
          type: "module",
        }
      );
      console.log("Registration complete", registration);
      if (registration.installing) {
        console.log("Service worker installing");
      } else if (registration.waiting) {
        console.log("Service worker installed");
      } else if (registration.active) {
        console.log("Service worker active");
      }
    } catch (error) {
      console.log("Error registering notification service worker", error);
    }
  } else {
    console.log("Navigator does not have ServiceWorker", navigator);
  }
};

console.log("About to register service worker");
registerServiceWorker().catch((error) =>
  console.log("Error initializing notification", error)
);

export function App(): JSX.Element {
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();
  const kai: KaiBridge = {
    async getAuthStatus(): Promise<AuthStatus> {
      if (!isAuthenticated) {
        return { authed: isAuthenticated };
      }
      const bearer = await getAccessTokenSilently({
        audience: "https://backend.kaimerra.com",
        scope: "user",
      });
      return { authed: isAuthenticated, bearer };
    },
  };
  // Get ready
  return (
    <Router>
      <Box top="0" left="0" bg="#2F3136">
        <Header />
        <HStack color="white" height="full">
          <Box h="full" flexGrow={1} bg="#36393F" margin={2}>
            <Routes>
              <Route path="/" element={<FeedContainer kai={kai} />} />
              <Route
                path="/feed/c/:channelName"
                element={<ChannelContainer kai={kai} />}
              />
              <Route
                path="/feed/:feedItemId"
                element={<FeedItemContainer inventory kai={kai} />}
              />
              <Route path="/profile" element={<Profile />} />
            </Routes>
          </Box>
        </HStack>
      </Box>
    </Router>
  );
}

export default App;
