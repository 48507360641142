import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from "react";

import { AuthStatus, KaiBridge } from "../bridge";
import Settings from "../settings";
import { FeedItem } from "../shared-types";
import { Feed } from "./feed";

type FeedContainerProps = {
  kai: KaiBridge;
};

export function FeedContainer({ kai }: FeedContainerProps): JSX.Element {
  const [feed, setFeed] = useState<FeedItem[]>([]);
  const { isAuthenticated } = useAuth0();

  useEffect(() => {
    const interval = setInterval(async () => {
      if (!isAuthenticated) {
        const response = await fetch(`${Settings.API_HOST}/feed`);
        const results: FeedItem[] = (await response.json()) as FeedItem[];
        setFeed(results);
      } else {
        const status: AuthStatus = await kai.getAuthStatus();
        if (status.bearer) {
          const response = await fetch(`${Settings.API_HOST}/feed`, {
            headers: {
              Authorization: `Bearer ${status.bearer}`,
            },
          });
          const results: FeedItem[] = (await response.json()) as FeedItem[];
          setFeed(results);
        } else {
          const response = await fetch(`${Settings.API_HOST}/feed}`);
          const results: FeedItem[] = (await response.json()) as FeedItem[];
          setFeed(results);
        }
      }
    }, 2000);
    return () => {
      clearInterval(interval);
    };
  }, [isAuthenticated, kai]);

  return <Feed kai={kai} feed={feed} />;
}

export default FeedContainer;
