import { useAuth0 } from "@auth0/auth0-react";
import { Box, Divider, HStack, Icon, VStack } from "@chakra-ui/react";
import { useState } from "react";
import { BiVolumeFull, BiVolumeMute } from "react-icons/bi";
import { InView } from "react-intersection-observer";
import { Link } from "react-router-dom";
import { DotLoader } from "react-spinners";

import * as logoPath from "../../assets/header4.png";
import { KaiBridge } from "../bridge";
import { Giphy } from "../giphy";
import { FeedItem } from "../shared-types";
import { FeedItemContent } from "./feed-item-content";

type FeedProps = {
  feed: FeedItem[];
  kai: KaiBridge;
};

export function Feed({ kai, feed }: FeedProps): JSX.Element {
  const [globalMuted, setGlobalMuted] = useState<boolean>(true);
  const { isAuthenticated } = useAuth0();

  return (
    <VStack className="vp" height="full" bg="#2F3136">
      <HStack>
        <Link to="/">
          <Box>
            <img
              width="430px"
              height="80px"
              src={logoPath}
              alt={"Logo that is a blend of 80's cyber outrun and 90's extreme"}
            />
          </Box>
        </Link>
        {globalMuted ? (
          <Box
            onClick={() => {
              setGlobalMuted(!globalMuted);
            }}
          >
            <Icon w={22} h={22} margin={3} as={BiVolumeMute} />
          </Box>
        ) : (
          <Box
            onClick={() => {
              setGlobalMuted(!globalMuted);
            }}
          >
            <Icon w={22} h={22} margin={3} as={BiVolumeFull} />
          </Box>
        )}
        ;
      </HStack>
      {isAuthenticated && <Giphy kai={kai} />}
      {feed && feed.length > 0 ? (
        [...feed].reverse().map((feedItem) => (
          <InView
            key={feedItem._id}
            threshold={0.85}
            delay={2500}
            onChange={async (inView, entry) => {
              const video = entry.target
                .querySelectorAll(".video")
                .item(0) as HTMLVideoElement;
              if (inView) {
                await video.play();
              } else {
                video.pause();
              }
            }}
          >
            {({ ref }) => (
              <div ref={ref}>
                <FeedItemContent
                  feedItem={feedItem}
                  muted={globalMuted}
                  kai={kai}
                />
                <Divider orientation="horizontal" />
              </div>
            )}
          </InView>
        ))
      ) : (
        <DotLoader size={150} />
      )}
    </VStack>
  );
}

export default Feed;
