import { useAuth0 } from "@auth0/auth0-react";
import { usePrevious } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { Navigate, useParams } from "react-router";

import { AuthStatus, KaiBridge } from "../bridge";
import Settings from "../settings";
import { FeedItem } from "../shared-types";
import { Feed } from "./feed";

type ChannelProps = {
  kai: KaiBridge;
};

function showNotification(message: string, channelName: string): void {
  const title = "Kaimerra";
  const icon = "";
  navigator.serviceWorker.ready
    .then((registration) => {
      console.log(registration);
      return registration
        .showNotification(title, {
          body: message,
          icon,
          vibrate: [200, 100, 200, 100, 200, 100, 200],
          tag: `https://app.kaimerra.com/#/feed/c/${channelName}`
        })
        .then(() => console.log("created notification"))
        .catch((error) => console.log("Error showing notification", error));
    })
    .catch((error) => console.log("Error rendering notification", error));
}

function requestAndShowPermission(message: string, channelName: string): void {
  Notification.requestPermission()
    .then((permission) => {
      if (permission === "granted") {
        showNotification(message, channelName);
      }
      return null;
    })
    .catch((error) => {
      console.log(error);
    });
}

function alertUser(lastestItem: FeedItem): void {
  const { permission } = Notification;
  const message = `update from ${lastestItem.userName}: ${lastestItem.name}`;
  if (permission === "granted") {
    console.log("attempting to show notification");
    showNotification(message, lastestItem.userName);
  } else if (permission === "default") {
    requestAndShowPermission(message, lastestItem.userName);
  } else {
    alert(`${message} and permission is ${permission}`);
  }
}

export function ChannelContainer({ kai }: ChannelProps): JSX.Element {
  const [feed, setFeed] = useState<FeedItem[]>([]);
  const { channelName } = useParams<{ channelName: string }>();
  const { isAuthenticated } = useAuth0();

  const prevFeed = usePrevious(feed);
  if (prevFeed && feed.length > prevFeed.length) {
    const lastestItem: FeedItem = feed.slice(-1)[0];
    alertUser(lastestItem);
  }

  useEffect(() => {
    if (!channelName) {
      return () => {};
    }

    const interval = setInterval(async () => {
      if (!isAuthenticated) {
        const response = await fetch(
          `${Settings.API_HOST}/feed/c/${channelName}`
        );
        const results: FeedItem[] = (await response.json()) as FeedItem[];
        setFeed(results);
      } else {
        const status: AuthStatus = await kai.getAuthStatus();
        if (status.bearer) {
          const response = await fetch(
            `${Settings.API_HOST}/feed/c/${channelName}`,
            {
              headers: {
                Authorization: `Bearer ${status.bearer}`,
              },
            }
          );
          const results: FeedItem[] = (await response.json()) as FeedItem[];
          setFeed(results);
        } else {
          const response = await fetch(
            `${Settings.API_HOST}/feed/c/${channelName}`
          );
          const results: FeedItem[] = (await response.json()) as FeedItem[];
          setFeed(results);
        }
      }
    }, 2000);

    return () => {
      clearInterval(interval);
    };
  }, [channelName, isAuthenticated, kai]);

  return (
    <>{channelName ? <Feed kai={kai} feed={feed} /> : <Navigate to="/" />};</>
  );
}

export default ChannelContainer;
