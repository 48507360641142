import { useAuth0 } from "@auth0/auth0-react";
import {
  Avatar,
  Box,
  Button,
  Center,
  Flex,
  Heading,
  Image,
  Stack,
  Text,
  useClipboard,
  useColorModeValue,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { DotLoader } from "react-spinners";

import * as logoPath from "../assets/logo.png";

export function Profile(): JSX.Element {
  const { user, isLoading, isAuthenticated, getAccessTokenSilently } =
    useAuth0();

  const [bearer, setBearer] = useState("");
  const { hasCopied, onCopy } = useClipboard(bearer);
  const bg = useColorModeValue("#151f21", "gray.900");

  useEffect(() => {
    setBearer("");
    if (isAuthenticated) {
      (async () => {
        setBearer(
          await getAccessTokenSilently({
            audience: "https://backend.kaimerra.com",
            scope: "user",
          })
        );
      })().catch((error) => {
        throw error;
      });
    }
  }, [getAccessTokenSilently, isAuthenticated]);

  if (isLoading) {
    return <DotLoader />;
  }

  if (!isAuthenticated) {
    return <></>;
  }

  return (
    <Center py={6}>
      <Box maxW="500px" w="full" boxShadow="2xl" rounded="md" overflow="hidden">
        <Image h="120px" w="full" src={logoPath} objectFit="cover" />
        <Flex justify="center" mt={-12}>
          <Avatar
            size="xl"
            src={user?.picture}
            css={{
              border: "2px solid white",
            }}
          />
        </Flex>

        <Box p={6}>
          <Stack spacing={0} align="center" mb={5}>
            <Heading fontSize="2xl" fontWeight={500} fontFamily="body">
              {user?.name}
            </Heading>
            <Text color="gray.500">Kaimerra User</Text>
          </Stack>

          <Button
            w="full"
            mt={8}
            bg={bg}
            color="white"
            rounded="md"
            _hover={{
              transform: "translateY(-2px)",
              boxShadow: "lg",
            }}
            onClick={onCopy}
            isLoading={bearer === ""}
          >
            {hasCopied ? "Copied..." : "Copy Bearer Token"}
          </Button>
        </Box>
      </Box>
    </Center>
  );
}

export default Profile;
