import {
  Box,
  Button,
  Flex,
  HStack,
  Icon,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverFooter,
  PopoverHeader,
  PopoverTrigger,
  Portal,
  Text,
  Tooltip,
  useClipboard,
  VStack,
} from "@chakra-ui/react";
import { useState } from "react";
import {
  BsHandThumbsDown,
  BsHandThumbsDownFill,
  BsHandThumbsUp,
  BsHandThumbsUpFill,
  BsShare,
} from "react-icons/bs";
import { GiUnicorn } from "react-icons/gi";
import { Link } from "react-router-dom";

import { KaiBridge } from "../bridge";
import Settings from "../settings";
import { EngagementType, FeedItem } from "../shared-types";
import { Inventory } from "./inventory";

type FeedItemProps = {
  feedItem: FeedItem;
  muted: boolean;
  kai: KaiBridge;
  inventory?: boolean;
};

async function postEngage(
  id: string,
  engagementType: EngagementType,
  bearer: string
): Promise<void> {
  await fetch(`${Settings.API_HOST}/feed/engage`, {
    method: "POST",
    body: JSON.stringify({
      feedItemId: id,
      engagementType,
    }),
    headers: {
      Authorization: `Bearer ${bearer}`,
      "Content-Type": "application/json",
    },
  });
}

export function FeedItemContent({
  kai,
  feedItem,
  muted,
  inventory = false,
}: FeedItemProps): JSX.Element {
  const [localFeedItem, setFeedItem] = useState<FeedItem>(feedItem);
  const shareLink = `https://share.kaimerra.com/${feedItem._id}`;
  const { hasCopied, onCopy } = useClipboard(shareLink);

  const engage = async (
    id: string,
    engagement: EngagementType,
    likesChange: number,
    dislikesChange: number
  ): Promise<void> => {
    const status = await kai.getAuthStatus();
    if (status.authed && status.bearer) {
      await postEngage(id, engagement, status.bearer);
      const updatedFeedItem = {
        ...localFeedItem,
        userEngagement: engagement,
        likes: localFeedItem.likes + likesChange,
        dislikes: localFeedItem.dislikes + dislikesChange,
      };
      setFeedItem(updatedFeedItem);
    }
  };

  return (
    <VStack>
      <VStack>
        <HStack width={400} justify="left" spacing={1}>
          <Link to={`/feed/c/${localFeedItem.userName}`}>
            <Icon w={22} h={22} margin={2} as={GiUnicorn} />
          </Link>
          <Box flexGrow={1}>
            <Link to={`/feed/${localFeedItem._id}`}>
              <VStack justify="left" align="left" spacing="1">
                <Text color="gold" fontSize="14">
                  {localFeedItem.userName}
                </Text>
                <Text color="white" fontSize="14">
                  {localFeedItem.name}
                </Text>
              </VStack>
            </Link>
          </Box>
        </HStack>
        <Box w={400}>
          <video
            crossOrigin="anonymous"
            className="video"
            controls
            muted={muted}
            preload="none"
            height={localFeedItem.height}
            width={localFeedItem.width}
            poster={localFeedItem.thumbnail_url}
            loop
          >
            <source src={localFeedItem.video_url} type="video/webm" />
          </video>
        </Box>
        <HStack width={250} spacing={6} align="stretch" justify="center">
          <Flex>
            <Tooltip label="I like this" fontSize="md">
              {{
                liked: (
                  <Box
                    margin={2}
                    onClick={() => engage(feedItem._id, "unengaged", -1, 0)}
                  >
                    <BsHandThumbsUpFill />
                  </Box>
                ),
                disliked: (
                  <Box
                    margin={2}
                    onClick={() => engage(feedItem._id, "liked", 1, -1)}
                  >
                    <BsHandThumbsUp />
                  </Box>
                ),
                unengaged: (
                  <Box
                    margin={2}
                    onClick={() => engage(feedItem._id, "liked", 1, 0)}
                  >
                    <BsHandThumbsUp />
                  </Box>
                ),
              }[localFeedItem?.userEngagement] || (
                <Box
                  margin={2}
                  onClick={() => engage(localFeedItem._id, "liked", 1, 0)}
                >
                  <BsHandThumbsUp />
                </Box>
              )}
            </Tooltip>
            <Text margin={1}>{localFeedItem.likes}</Text>
          </Flex>
          <Flex>
            <Tooltip label="I dislike this" fontSize="md">
              {{
                liked: (
                  <Box
                    margin={2}
                    onClick={() => engage(localFeedItem._id, "disliked", -1, 1)}
                  >
                    <BsHandThumbsDown />
                  </Box>
                ),
                disliked: (
                  <Box
                    margin={2}
                    onClick={() =>
                      engage(localFeedItem._id, "unengaged", 0, -1)
                    }
                  >
                    <BsHandThumbsDownFill />
                  </Box>
                ),
                unengaged: (
                  <Box
                    margin={2}
                    onClick={() => engage(feedItem._id, "disliked", 0, 1)}
                  >
                    <BsHandThumbsDown />
                  </Box>
                ),
              }[localFeedItem?.userEngagement] || (
                <Box
                  margin={2}
                  onClick={() => engage(localFeedItem._id, "disliked", 0, 1)}
                >
                  <BsHandThumbsDown />
                </Box>
              )}
            </Tooltip>
            <Text margin={1}>{localFeedItem.dislikes}</Text>
          </Flex>
          <Flex>
            <Popover>
              <Tooltip label="Share" fontSize="md">
                <Box>
                  <PopoverTrigger>
                    <Box margin={2}>
                      <BsShare />
                    </Box>
                  </PopoverTrigger>
                </Box>
              </Tooltip>
              <Portal>
                <PopoverContent color="white" bg="black" borderColor="black">
                  <PopoverArrow />
                  <PopoverHeader pt={4} fontWeight="bold" border="0">
                    Share
                  </PopoverHeader>
                  <PopoverCloseButton />
                  <PopoverBody bg="black">
                    <HStack w="full" align="stretch">
                      <Box w={225}>
                        <Text fontSize="sm">{shareLink}</Text>
                      </Box>
                      <Button
                        variant="outline"
                        colorScheme="white"
                        onClick={onCopy}
                      >
                        COPY
                      </Button>
                    </HStack>
                  </PopoverBody>
                  <PopoverFooter>
                    {hasCopied ? "Copied to clipboard" : ""}
                  </PopoverFooter>
                </PopoverContent>
              </Portal>
            </Popover>
          </Flex>
        </HStack>

        {inventory && feedItem.annotations && (
          <Inventory annotations={feedItem.annotations} />
        )}
      </VStack>
    </VStack>
  );
}

export default FeedItemContent;
