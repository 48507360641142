import { IGif } from "@giphy/js-types";
import {
  Grid, // our UI Component to display the results
  SearchBar, // the search bar the user will type into
  SearchContext, // the context that wraps and connects our components
  SearchContextManager, // the context manager, includes the Context.Provider
} from "@giphy/react-components";
import { SyntheticEvent, useCallback, useContext } from "react";

import { KaiBridge } from "./bridge";
import Settings from "./settings";
import { FeedItem } from "./shared-types";

async function createFeedItem(
  feedItem: Partial<FeedItem>,
  bearer: string
): Promise<Response> {
  return fetch(`${Settings.API_HOST}/feed`, {
    method: "POST",
    body: JSON.stringify(feedItem),
    headers: {
      Authorization: `Bearer ${bearer}`,
      "Content-Type": "application/json",
    },
  });
}

function Components({ kai }: { kai: KaiBridge }): JSX.Element {
  const { fetchGifs, searchKey, setActiveChannel, setSearch } =
    useContext(SearchContext);

  const shareGiphy = useCallback(
    async (gif: IGif) => {
      const feedItem = {
        video_url: `https://i.giphy.com/media/${gif.id}/giphy.mp4`,
        name: "",
        height: gif.images.original_mp4.height,
        width: gif.images.original_mp4.width,
      };
      const { bearer } = await kai.getAuthStatus();
      if (bearer) {
        return createFeedItem(feedItem, bearer);
      }
      return null;
    },
    [kai]
  );

  const onGifClick = useCallback(
    async (gif: IGif, e: SyntheticEvent<HTMLElement, Event>) => {
      await shareGiphy(gif);
      setSearch("");
      e.stopPropagation();
      e.preventDefault();
      setActiveChannel();
    },
    [setActiveChannel, setSearch, shareGiphy]
  );

  return (
    <>
      <SearchBar />
      <Grid
        key={searchKey}
        columns={3}
        width={800}
        fetchGifs={fetchGifs}
        onGifClick={onGifClick}
        noLink
      />
    </>
  );
}

export function Giphy({ kai }: { kai: KaiBridge }): JSX.Element {
  return (
    <SearchContextManager
      // eslint-disable-next-line no-secrets/no-secrets
      apiKey="B8Y6xmjjjfqFDQKmYhDtDW5XQxbdaEbS"
      shouldFetchChannels={false}
      shouldDefaultToTrending={false}
    >
      <Components kai={kai} />
    </SearchContextManager>
  );
}

export default Giphy;
